<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Filter Pencarian
            </div>
                <!-- <div class="p-5"></div> -->
            <div class="card-body row">
                <div class="col-md-6 form-group">
                    <label class="control-label">Tanggal</label>
                    <datepicker
                      placeholder="Tanggal"
                      class="datep"
                      v-model="form.f_tanggal"
                      :options="{format:'yyyy-mm-dd', autoclose: true, orientation:'bottom'}" 
                      :autoclose=true
                      required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Kelas</label>
                    <v-select :options="optKelas" v-model="form.kelas_id" :reduce="opt => opt.id" />
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>

        <table class="table table-hover" ref="tableuser">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAMA SISWA</th>
                        <th>NO. PENDAFTARAN</th>
                        <th>KEHADIRAN</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
        </table>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
    name: 'AbsensiSiswa',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            optKelas: [],
            roles: '',
            form: {
                name: '',
                f_tanggal: '',
                kelas_id: '',
                absen: '',
            },
            tgl: '',
            kelas: '',
        }
    },
    created: function() {
        this.form.f_tanggal = moment().format("YYYY-MM-DD");
        // this.roles = this.$route.meta.roles;
        // console.log('load initial data', this.$route)

        authFetch('/akademik/jadwal_tentor/kelas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optKelas = js.data;
        });
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('input#masuk')) {
                //masuk
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0,
                    1,
                    this.form.f_tanggal
                    );
            }
            if (e.target.matches('input#sakit')) {
                //sakit
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0,
                    2,
                    this.form.f_tanggal
                    );
            }
            if (e.target.matches('input#ijin')) {
                //ijin
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0,
                    3,
                    this.form.f_tanggal
                    );
            }
            if (e.target.matches('input#alpha')) {
                //alpha
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0,
                    4,
                    this.form.f_tanggal
                    );
            }
        },
        search: function() {
            let self = this;
            self.tgl = self.form.f_tanggal;
            self.kelas = self.form.kelas_id;
            this.table.api().ajax.reload();
        },
        suffleOption(id, key, action, push_type, tgl) {
            var urlSubmit = '/akademik/absensi_siswa/absen';
            var datas = {
                        action: action,
                        key: key,
                        siswa_id: id,
                        push_type: push_type,
                        tgl: tgl,
                    };
            var data = Object.keys(datas).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(datas[key])).join('&')

            authFetch(urlSubmit, {
                    method: 'POST',
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload(null, false);
                });
        },
    },
    mounted() {
        console.log('load initial data', this.$route.params.roles);
        const e = this.$refs;
        var self = this;

        this.table = createTable(e.tableuser, {
            title: "",
            roles: ["read"],
            ajax: "/akademik/absensi_siswa",
            columns: [
                { data: "id",
                sortable: false,
                visible: false, },
                { data: "nama_siswa" },
                { data: "nomor_pendaftaran" },
                { data: "opt",
                sortable: false },
            ],
            filterBy: [1, 2],
            rowCallback: function(row, data) {},
            paramData: function ( d ) {
                d.tgl = self.tgl;
                d.kelas_id = self.kelas;
            },
        });
    }
}
</script>